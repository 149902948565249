import { API_TYPE_CALL_API, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const MODULE = `${SERVER}/module`;
const MODULE_GETS = MODULE;

export const getModulesService = async (idBuilding) => {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: MODULE_GETS,
      method: METHOD.GET,
      options: {
        headers,
      },
    },
  });
  return response;
};
