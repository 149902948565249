import { API_TYPE_CALL_API, METHOD, SERVER_NOTIFICATION, X_LANGUAGE, X_UNIT_GROUP_ID } from 'shared/constants';
import { getCacheLanguage } from 'shared/utils';
import api from './api';

const NOTIFICATION_GETS = `${SERVER_NOTIFICATION}/histories/pms`;
const NOTIFICATION_COUNT_BADGES = `${SERVER_NOTIFICATION}/histories/count-badges`;
const NOTIFICATION_COUNT_BADGES_OF_ALL_BUILDING = `${SERVER_NOTIFICATION}/histories/count-badges/multi-unit-group`;
const NOTIFICATION_READ_ALL = `${SERVER_NOTIFICATION}/histories/read-all`;
const NOTIFICATION_READ_ONE = (id) => `${SERVER_NOTIFICATION}/histories/${id}/read`;
const NOTIFICATION_VIEW_ALL = `${SERVER_NOTIFICATION}/histories/view-all`;

export async function getNotificationHistoriesService({ idBuilding, page, limit, filter }) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
    [X_LANGUAGE]: getCacheLanguage(),
  };
  const params = {
    page,
    limit,
    ...filter,
  };
  const response = api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: NOTIFICATION_GETS,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function countBadgesNotificationService(idBuilding) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: NOTIFICATION_COUNT_BADGES,
      method: METHOD.GET,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function countBadgesNotificationOfBuildingsService(userId, idBuildings) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: NOTIFICATION_COUNT_BADGES_OF_ALL_BUILDING,
      method: METHOD.POST,
      options: {},
      body: {
        unitGroupIds: idBuildings,
        userId,
      },
    },
  });
  return response;
}

export async function viewAllNotificationService(idBuilding) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: NOTIFICATION_VIEW_ALL,
      method: METHOD.PUT,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function readAllNotificationService(idBuilding) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: NOTIFICATION_READ_ALL,
      method: METHOD.PUT,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function readNotificationService(idBuilding, idNotification) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: NOTIFICATION_READ_ONE(idNotification),
      method: METHOD.PATCH,
      options: {
        headers,
      },
    },
  });
  return response;
}
