import moment from 'moment';
import { convertArrToObj } from 'shared/utils';
import { language } from '../utils/language';

export const AMENITIES_STATUS_ACTIVE = 'ACTIVE';
export const AMENITIES_STATUS_INACTIVE = 'INACTIVE';
export const AMENITIES_STATUS_MAPPING = {
  [AMENITIES_STATUS_ACTIVE]: language.active,
  [AMENITIES_STATUS_INACTIVE]: language.inactive,
};

export const AMENITIES_TYPE_NOT_PRE_ORDER = 'NO_RESERVATION';
export const AMENITIES_TYPE_PRE_ORDER_PERIOD_TIME = 'TIME_FRAME';
export const AMENITIES_TYPE_PRE_ORDER_TIME = 'SLOT_TIME';
export const AMENITIES_TYPE_MAPPING = {
  [AMENITIES_TYPE_NOT_PRE_ORDER]: language.amenities_type_not_pre_order,
  [AMENITIES_TYPE_PRE_ORDER_PERIOD_TIME]: language.amenities_type_pre_order_by_period_time,
  [AMENITIES_TYPE_PRE_ORDER_TIME]: language.amenities_type_pre_order_by_time,
};
export const AMENITIES_TYPE_ARRAY = [
  {
    label: language.amenities_type_not_pre_order,
    key: AMENITIES_TYPE_NOT_PRE_ORDER,
  },
  {
    label: language.amenities_type_pre_order_by_period_time,
    key: AMENITIES_TYPE_PRE_ORDER_PERIOD_TIME,
  },
  {
    label: language.amenities_type_pre_order_by_time,
    key: AMENITIES_TYPE_PRE_ORDER_TIME,
  },
];

export const AMENITIES_PRE_ORDER_TIME_DISTANCE_15_MINUTE = 15;
export const AMENITIES_PRE_ORDER_TIME_DISTANCE_30_MINUTE = 30;
export const AMENITIES_PRE_ORDER_TIME_DISTANCE_1_HOUR = 60;

export const AMENITIES_PRE_ORDER_TIME_DISTANCE_ARRAY = [
  {
    label: language.amenities_pre_order_time_distance_15_minute,
    key: AMENITIES_PRE_ORDER_TIME_DISTANCE_15_MINUTE,
  },
  {
    label: language.amenities_pre_order_time_distance_30_minute,
    key: AMENITIES_PRE_ORDER_TIME_DISTANCE_30_MINUTE,
  },
  {
    label: language.amenities_pre_order_time_distance_1_hour,
    key: AMENITIES_PRE_ORDER_TIME_DISTANCE_1_HOUR,
  },
];

export const AMENITIES_SERVE_DAY_SUNDAY = 0;
export const AMENITIES_SERVE_DAY_MONDAY = 1;
export const AMENITIES_SERVE_DAY_TUESDAY = 2;
export const AMENITIES_SERVE_DAY_WEDNESDAY = 3;
export const AMENITIES_SERVE_DAY_THURSDAY = 4;
export const AMENITIES_SERVE_DAY_FRIDAY = 5;
export const AMENITIES_SERVE_DAY_SATURDAY = 6;

export const AMENITIES_SERVE_DAY_ARRAY = [
  {
    label: language.amenities_serve_day_monday,
    key: AMENITIES_SERVE_DAY_MONDAY,
  },
  {
    label: language.amenities_serve_day_tuesday,
    key: AMENITIES_SERVE_DAY_TUESDAY,
  },
  {
    label: language.amenities_serve_day_wednesday,
    key: AMENITIES_SERVE_DAY_WEDNESDAY,
  },
  {
    label: language.amenities_serve_day_thursday,
    key: AMENITIES_SERVE_DAY_THURSDAY,
  },
  {
    label: language.amenities_serve_day_friday,
    key: AMENITIES_SERVE_DAY_FRIDAY,
  },
  {
    label: language.amenities_serve_day_saturday,
    key: AMENITIES_SERVE_DAY_SATURDAY,
  },
  {
    label: language.amenities_serve_day_sunday,
    key: AMENITIES_SERVE_DAY_SUNDAY,
  },
];

export const PAYMENT_EXPIRED_DATE_TYPE_HOUR = 'HOUR';
export const PAYMENT_EXPIRED_DATE_TYPE_DATE = 'DAY';
export const PAYMENT_EXPIRED_DATE_TYPES = [
  {
    key: PAYMENT_EXPIRED_DATE_TYPE_HOUR,
    label: language.hour,
  },
  {
    key: PAYMENT_EXPIRED_DATE_TYPE_DATE,
    label: language.date,
  },
];

export const PAYMENT_METHOD_ONLINE = 'ONLINE';
export const PAYMENT_METHOD_AT_THE_COUNTER = 'CHECKOUT';
export const PAYMENT_METHOD_ALL = 'ALL';
export const PAYMENT_METHODS = [
  // {
  //   key: PAYMENT_METHOD_ALL,
  //   label: language.all,
  // },
  // {
  //   key: PAYMENT_METHOD_ONLINE,
  //   label: language.payment_method_online,
  // },
  {
    key: PAYMENT_METHOD_AT_THE_COUNTER,
    label: language.payment_method_at_the_counter,
  },
];

export const AMENITIES_LIMIT_ORDER_PER_UNIT_TIME_WEEK = 'WEEK';
export const AMENITIES_LIMIT_ORDER_PER_UNIT_TIME_MONTH = 'MONTH';
export const AMENITIES_LIMIT_ORDER_PER_UNIT_TIMES = [
  {
    key: AMENITIES_LIMIT_ORDER_PER_UNIT_TIME_WEEK,
    label: language.one_week.toLowerCase(),
  },
  {
    key: AMENITIES_LIMIT_ORDER_PER_UNIT_TIME_MONTH,
    label: language.one_month.toLowerCase(),
  },
];

export const AMENITIES_REFUNDABLE_TYPE_REFUNDABLE = 'REFUND';
export const AMENITIES_REFUNDABLE_TYPE_NOT_REFUNDABLE = 'NO_REFUND';
export const AMENITIES_REFUNDABLE_TYPES = [
  {
    key: AMENITIES_REFUNDABLE_TYPE_REFUNDABLE,
    label: language.refundable,
  },
  {
    key: AMENITIES_REFUNDABLE_TYPE_NOT_REFUNDABLE,
    label: language.not_refundable,
  },
];

export const AMENITIES_REFUND_LEVEL_PERCENT = 'PERCENT';
export const AMENITIES_REFUND_LEVEL_FIXED = 'FIXED_PRICE';
export const AMENITIES_REFUND_LEVELS = [
  {
    key: AMENITIES_REFUND_LEVEL_PERCENT,
    label: language.percent,
  },
  {
    key: AMENITIES_REFUND_LEVEL_FIXED,
    label: language.fixed,
  },
];

export const AMENITIES_FORMULA_TYPE_NUMBER_USE = 'NUMBER_USE';
export const AMENITIES_FORMULA_TYPE_ACCUMULATED = 'ACCUMULATED';

export const AMENITIES_FORMULA_STATUS_ACTIVE = 'ACTIVE';
export const AMENITIES_FORMULA_STATUS_INACTIVE = 'INACTIVE';

export const AMENITIES_DEFAULT_VALUE = {
  amenitiesName: '',
  amenitiesStatus: false,
  amenitiesType: AMENITIES_TYPE_NOT_PRE_ORDER,
  amenitiesCode: '',
  amenitiesPaymentMethod: null,
  amenitiesPaymentExpiredNumberTime: null,
  amenitiesPaymentExpiredUnitTime: null,
  amenitiesLocation: '',
  amenitiesOrderInResidentApp: undefined,
  amenitiesDescription: '',
  amenitiesAvatar: '',
  amenitiesDescriptionImages: [],
  amenitiesStartDateServe: moment(),
  amenitiesEndDateServe: moment(),
  amenitiesTimeServes: [
    {
      amenitiesDayServe: AMENITIES_SERVE_DAY_MONDAY,
      amenitiesPeriodTimeServe: [moment('07:00', 'HH:mm'), moment('23:00', 'HH:mm')],
    },
  ],
  amenitiesLimitServe: 1,
  amenitiesLimitFrameOrder: {
    from: '',
    to: '',
  },
  amenitiesPreOrder: {
    from: '',
    to: '',
  },
  amenitiesPreOrderLimit: {
    time: undefined,
    unit: undefined,
  },
  // time
  amenitiesFormulas: [
    {
      amenitiesFormula: {
        to: '',
      },
      amenitiesFormulaPrice: '',
    },
  ],
  // period time
  amenitiesPeriodTimePrice: '',
  amenitiesDepositAmount: '',
  amenitiesSurcharges: [],
  amenitiesCancelOrderPolicies: [],
};

export const AMOUNT_DATES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const PAYMENT_EXPIRED_DAYS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const TIMES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const MAX_PRIORITY_IN_RESIDENT_APP = 100000;
export const MAX_LIMIT_SERVE = 100000;
export const MAX_PRE_ORDER = 60;
export const MAX_LIMIT_FRAME_IN_ORDER = 24;
export const MAX_TIME = 744;
export const MAX_PRICE = 2000000000;
export const MAX_DATE_CANCEL_ORDER_POLICY = 10;

export const CONFIG_AMENITY_DEFAULT_FILTER = {
  name: undefined,
  type: undefined,
  form: undefined,
  to: undefined,
};

export const AMENITIES_ORDER_ACTION_TYPE_REFUND = 'REFUND';
export const AMENITIES_ORDER_ACTION_TYPE_PAYMENT = 'PAYMENT';

export const AMENITIES_ORDER_ACCEPT = 'ACCEPT';
export const AMENITIES_ORDER_REJECT = 'REJECT';
export const AMENITIES_ORDER_CANCEL = 'CANCEL';

export const AMENITIES_ORDER_PAYMENT_AMENITY_SURCHARGE = 'AMENITY_SURCHARGE';
export const AMENITIES_ORDER_PAYMENT_AMENITY_FEE = 'AMENITY_FEE';
export const AMENITIES_ORDER_PAYMENT_AMENITY_DEPOSITS = 'AMENITY_DEPOSITS';

export const AMENITIES_ORDER_REFUND_AMENITY_DEPOSITS = 'REFUND_AMENITY_DEPOSITS';
export const AMENITIES_ORDER_REFUND_AMENITY_FEE = 'REFUND_AMENITY_FEE';

export const AMENITIES_TAB_ORDER_DETAIL = 'ORDER_DETAIL';
export const AMENITIES_TAB_BILL_DETAIL = 'BILL_DETAIL';
export const AMENITIES_TAB_RECEIPT_DETAIL = 'RECEIPT_DETAIL';
export const AMENITIES_TAB_PAYSLIP_DETAIL = 'PAYSLIP_DETAIL';

export const AMENITIES_ORDER_STATUS_UNCONFIRMED = 'PENDING';
export const AMENITIES_ORDER_STATUS_WAITING_CANCEL = 'WAITING_FOR_CANCELLED';
export const AMENITIES_ORDER_STATUS_WAITING_FINISH = 'WAITING_FOR_COMPLETED';
export const AMENITIES_ORDER_STATUS_CONFIRMED = 'APPROVED';
export const AMENITIES_ORDER_STATUS_FINISHED = 'COMPLETED';
export const AMENITIES_ORDER_STATUS_CANCELED = 'CANCELLED';
export const AMENITIES_ORDER_STATUS_ALL = 'ALL';

export const AMENITIES_ORDER_STATUS_ARRAY = [
  {
    key: AMENITIES_ORDER_STATUS_UNCONFIRMED,
    label: language.unconfirmed,
  },
  {
    key: AMENITIES_ORDER_STATUS_WAITING_CANCEL,
    label: language.waiting_cancel,
  },
  {
    key: AMENITIES_ORDER_STATUS_WAITING_FINISH,
    label: language.waiting_finish,
  },
  {
    key: AMENITIES_ORDER_STATUS_CONFIRMED,
    label: language.confirmed,
  },
  {
    key: AMENITIES_ORDER_STATUS_FINISHED,
    label: language.finished,
  },
  {
    key: AMENITIES_ORDER_STATUS_CANCELED,
    label: language.canceled,
  },
  {
    key: AMENITIES_ORDER_STATUS_ALL,
    label: language.all,
  },
];

export const AMENITIES_ORDER_STATUS_MAPPING = {
  [AMENITIES_ORDER_STATUS_UNCONFIRMED]: language.unconfirmed,
  [AMENITIES_ORDER_STATUS_WAITING_CANCEL]: language.waiting_cancel,
  [AMENITIES_ORDER_STATUS_WAITING_FINISH]: language.waiting_finish,
  [AMENITIES_ORDER_STATUS_CONFIRMED]: language.confirmed,
  [AMENITIES_ORDER_STATUS_FINISHED]: language.finished,
  [AMENITIES_ORDER_STATUS_CANCELED]: language.canceled,
  [AMENITIES_ORDER_STATUS_ALL]: language.all,
};

export const AMENITIES_ORDER_ACTIONS = [
  {
    key: AMENITIES_ORDER_ACCEPT,
    label: language.accept,
    icon: 'check',
  },
  {
    key: AMENITIES_ORDER_REJECT,
    label: language.reject,
    icon: 'times',
  },
];

export const AMENITIES_BILL_SURCHARGE = 'UTILITY_SURCHARGE';
export const AMENITIES_BILL_UTILITY = 'UTILITY';

export const AMENITIES_ORDER_DEFAULT_FILTER = {
  code: '',
  name: '',
  unitName: '',
  orderTime: [],
  status: AMENITIES_ORDER_STATUS_UNCONFIRMED,
  residentName: '',
};

export const AMENITIES_ORDER_ACTION_CONTENT = {
  [AMENITIES_ORDER_ACCEPT]: {
    description: language.approve_amenities_order_success,
    title: language.confirm_approve_order,
    question: language.you_are_approving_order,
  },
  [AMENITIES_ORDER_REJECT]: {
    description: language.reject_amenities_order_success,
    title: language.confirm_reject_order,
    question: language.you_are_rejecting_order,
  },
  [AMENITIES_ORDER_CANCEL]: {
    description: language.reject_amenities_order_success,
    title: language.confirm_cancel_order,
    question: language.you_are_cancelling_order,
  },
};

export const TIME_TYPE_MINUTE = 'MINUTE';
export const TIME_TYPE_HOUR = 'HOUR';
export const TIME_TYPE_DAY = 'DAY';
export const TIME_TYPE_WEEK = 'WEEK';
export const TIME_TYPE_MONTH = 'MONTH';

export const TIME_TYPES = [
  { key: TIME_TYPE_MINUTE, label: language.minute },
  { key: TIME_TYPE_HOUR, label: language.hour },
  { key: TIME_TYPE_DAY, label: language.date },
  { key: TIME_TYPE_WEEK, label: language.week },
  { key: TIME_TYPE_MONTH, label: language.month },
];

export const TIME_TYPE_MOMENT = {
  [TIME_TYPE_MINUTE]: 'minutes',
  [TIME_TYPE_HOUR]: 'hours',
  [TIME_TYPE_DAY]: 'days',
  [TIME_TYPE_WEEK]: 'weeks',
  [TIME_TYPE_MONTH]: 'months',
};

export const TIME_TYPE = convertArrToObj(TIME_TYPES, 'key');

export const AMENITIES_CATEGORY_TYPE_EDUCATION = 'EDUCATION';
export const AMENITIES_CATEGORY_TYPE_HEALTH_CARE = 'HEALTH_CARE';
export const AMENITIES_CATEGORY_TYPE_FACILITY = 'FACILITY';

export const AMENITIES_CATEGORY_TYPES = [
  {
    key: AMENITIES_CATEGORY_TYPE_EDUCATION,
    label: language.education,
  },
  {
    key: AMENITIES_CATEGORY_TYPE_HEALTH_CARE,
    label: language.health_care,
  },
  {
    key: AMENITIES_CATEGORY_TYPE_FACILITY,
    label: language.facility,
  },
];

export const AMENITIES_CATEGORY_TYPE_MAPPING = {
  [AMENITIES_CATEGORY_TYPE_EDUCATION]: language.education,
  [AMENITIES_CATEGORY_TYPE_HEALTH_CARE]: language.health_care,
  [AMENITIES_CATEGORY_TYPE_FACILITY]: language.facility,
};
