import { put, call, all, takeLatest } from 'redux-saga/effects';
import { approveAmenitiesOrdersService, cancelAmenitiesOrdersService, rejectAmenitiesOrdersService } from 'services/amenities';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import { CONFIRM_CHANGE_STATUS_AMENITIES_ORDER } from './confirm-change-status-amenities-order.types';

function* approveAmenitiesOrders(action) {
  try {
    const result = yield call(approveAmenitiesOrdersService, {
      headers: {
        [X_UNIT_GROUP_ID]: action.payload.amenitiesOrder.unitGroupId,
      },
      body: {
        bookingIds: [action.payload.amenitiesOrder.id],
      },
    });
    yield put({
      type: CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.APPROVES.SUCCESS,
      payload: result,
    });
    yield put({
      type: CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.APPROVES.REFRESH,
    });
  } catch (error) {
    yield put({
      type: CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.APPROVES.FAIL,
      payload: error,
    });
  }
}

function* rejectAmenitiesOrders(action) {
  try {
    const result = yield call(rejectAmenitiesOrdersService, {
      headers: {
        [X_UNIT_GROUP_ID]: action.payload.amenitiesOrder.unitGroupId,
      },
      body: {
        bookingIds: [action.payload.amenitiesOrder.id],
        rejectReason: action.payload.reason,
      },
    });
    yield put({
      type: CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.REJECTS.SUCCESS,
      payload: result,
    });
    yield put({
      type: CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.REJECTS.REFRESH,
    });
  } catch (error) {
    yield put({
      type: CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.REJECTS.FAIL,
      payload: error,
    });
  }
}

function* cancelAmenitiesOrders(action) {
  try {
    const result = yield call(cancelAmenitiesOrdersService, {
      id: action.payload.amenitiesOrder.id,
      body: { rejectReason: action.payload.reason },
      idBuilding: action.payload.amenitiesOrder.unitGroupId,
    });
    yield put({
      type: CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.CANCEL.SUCCESS,
      payload: result,
    });
    yield put({
      type: CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.CANCEL.REFRESH,
    });
  } catch (error) {
    yield put({
      type: CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.CANCEL.FAIL,
      payload: error,
    });
  }
}

export default function* watch() {
  yield all([
    takeLatest(CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.APPROVES.REQUEST, approveAmenitiesOrders),
    takeLatest(CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.REJECTS.REQUEST, rejectAmenitiesOrders),
    takeLatest(CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.CANCEL.REQUEST, cancelAmenitiesOrders),
  ]);
}
