import { getModulesService } from 'services/module';
// eslint-disable-next-line import/no-cycle
import { Context as GlobalContext } from 'globalContext';
import { useContext } from 'react';
import {
  LIST_ACCOUNT_CS,
  PERMISSION_ACTION_APPROVE,
  PERMISSION_ACTION_EXPORT,
  PERMISSION_ACTION_EXPORT_ENCRYPTED,
  PERMISSION_ACTION_READ,
  PERMISSION_ACTION_READ_ENCRYPTED,
  PERMISSION_ACTION_WRITE,
  UNIT_MEMBER_MODULE,
} from 'shared/constants';

const useModule = () => {
  const { moduleObj, setModuleObj } = useContext(GlobalContext);
  const getModules = async (idBuilding, email) => {
    const isUserCs = LIST_ACCOUNT_CS.includes(email);
    const response = await getModulesService(idBuilding);
    const modules = response?.data ?? [];
    const moduleObjResult = {};
    if (modules && modules.length) {
      modules.forEach((module) => {
        moduleObjResult[module.code] = module?.action || {
          [PERMISSION_ACTION_READ]: false,
          [PERMISSION_ACTION_WRITE]: false,
          [PERMISSION_ACTION_EXPORT]: false,
          [PERMISSION_ACTION_APPROVE]: false,
          [PERMISSION_ACTION_READ_ENCRYPTED]: false,
          [PERMISSION_ACTION_EXPORT_ENCRYPTED]: false,
        };
      });
      // set account cs
      if (isUserCs && moduleObjResult[UNIT_MEMBER_MODULE]) {
        moduleObjResult[UNIT_MEMBER_MODULE][PERMISSION_ACTION_READ] = false;
        moduleObjResult[UNIT_MEMBER_MODULE][PERMISSION_ACTION_READ_ENCRYPTED] = false;
      }
      setModuleObj(moduleObjResult);
    }
    return moduleObjResult;
  };

  const getPermissionByModuleCode = (moduleCode = null) => {
    if (!moduleCode) {
      return {
        [PERMISSION_ACTION_READ]: false,
        [PERMISSION_ACTION_WRITE]: false,
        [PERMISSION_ACTION_EXPORT]: false,
        [PERMISSION_ACTION_APPROVE]: false,
        [PERMISSION_ACTION_READ_ENCRYPTED]: false,
        [PERMISSION_ACTION_EXPORT_ENCRYPTED]: false,
      };
    }

    return (
      moduleObj?.[moduleCode] || {
        [PERMISSION_ACTION_READ]: false,
        [PERMISSION_ACTION_WRITE]: false,
        [PERMISSION_ACTION_EXPORT]: false,
        [PERMISSION_ACTION_APPROVE]: false,
        [PERMISSION_ACTION_READ_ENCRYPTED]: false,
        [PERMISSION_ACTION_EXPORT_ENCRYPTED]: false,
      }
    );
  };

  const checkPermissionViewByModuleCodes = (moduleCodes = []) => {
    if (!moduleCodes || !Array.isArray(moduleCodes) || !moduleCodes.length) {
      return false;
    }
    let result = false;
    for (let index = 0; index < moduleCodes.length; index += 1) {
      const moduleCode = moduleCodes[index];
      const { code, permissionAction = PERMISSION_ACTION_READ } = moduleCode;
      if (!code || !permissionAction) {
        result = false;
        // eslint-disable-next-line no-continue
        continue;
      }
      const permission = getPermissionByModuleCode(code);
      if (permission?.[permissionAction]) {
        result = permission?.[permissionAction] ?? false;
        break;
      }
    }
    return result;
  };

  return {
    moduleObj,
    getModules,
    getPermissionByModuleCode,
    checkPermissionViewByModuleCodes,
  };
};

export default useModule;
