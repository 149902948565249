import { API_TYPE_CALL_API, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const INFORMATION_BOARD_GETS = `${SERVER}/information-board`;
const INFORMATION_BOARD_REJECT = (id) => `${INFORMATION_BOARD_GETS}/${id}/reject`;
const INFORMATION_BOARD_APPROVE = (id) => `${INFORMATION_BOARD_GETS}/${id}/approve`;
const INFORMATION_BOARD_RESIDENT_LIKE_GETS = (id) => `${INFORMATION_BOARD_GETS}/${id}/residents`;
const INFORMATION_BOARD_COUNT_BADGES = `${INFORMATION_BOARD_GETS}/count-badges`;
const INFORMATION_BOARD_DELETE = (id) => `${INFORMATION_BOARD_GETS}/${id}/delete`;

export async function getInformationBoardService({ idBuilding, filter, page, limit }) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const params = {
    ...filter,
    page,
    limit,
  };
  const response = api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: INFORMATION_BOARD_GETS,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function rejectInformationBoardService(idBuilding, idInformationBoard, rejectReason) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const body = {
    rejectReason,
  };
  const response = api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: INFORMATION_BOARD_REJECT(idInformationBoard),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function deleteInformationBoardService(idBuilding, idInformationBoard) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: INFORMATION_BOARD_DELETE(idInformationBoard),
      method: METHOD.PATCH,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function approveInformationBoardService(idBuilding, idInformationBoard) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: INFORMATION_BOARD_APPROVE(idInformationBoard),
      method: METHOD.PATCH,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function getResidentLikeInformationBoardService({ idBuilding, idInformationBoard, page, limit }) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: INFORMATION_BOARD_RESIDENT_LIKE_GETS(idInformationBoard),
      method: METHOD.GET,
      options: {
        headers,
        params: {
          page,
          limit,
        },
      },
    },
  });

  return response;
}

export const countBadgesInformationBoardService = async (idBuilding) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: INFORMATION_BOARD_COUNT_BADGES,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};
