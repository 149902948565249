export const UNIT_GROUP_MODULE = 'UNIT_GROUP';
export const UNIT_MODULE = 'UNIT';
export const DASHBOARD_MODULE = 'DASHBOARD';
export const UNIT_MEMBER_MODULE = 'UNIT_MEMBER';
export const EMPLOYEE_MODULE = 'EMPLOYEE';
export const LABEL_MODULE = 'LABEL';
export const DOCUMENT_MODULE = 'DOCUMENT';
export const ACTIVITY_LOG_MODULE = 'ACTIVITY_LOG';
export const FUND_MODULE = 'FUND';
export const TEMPLATE_MODULE = 'TEMPLATE';
export const BILLING_SERVICE_MODULE = 'BILLING_SERVICE';
export const FEE_NOTIFICATION_MODULE = 'FEE_NOTIFICATION';
export const PAYMENT_SERVICE_MODULE = 'PAYMENT_SERVICE';
export const RECEIPT_MODULE = 'RECEIPT';
export const SERVICE_CONFIG_MODULE = 'SERVICE_CONFIG';
export const SERVICE_MODULE = 'SERVICE';
export const PAYMENT_VOUCHER_MODULE = 'PAYMENT_VOUCHER';
export const UNIT_WALLET_MODULE = 'UNIT_WALLET';
export const PAYMENT_ONLINE_MODULE = 'PAYMENT_ONLINE';
export const AMENITY_CONFIG_MODULE = 'AMENITY_CONFIG';
export const AMENITY_BOOKING_MODULE = 'AMENITY_BOOKING';
export const THIRD_PARTY_AMENITY_BOOKING_MODULE = 'THIRD_PARTY_AMENITY_BOOKING';
export const CHAT_MODULE = 'CHAT';
export const ARCHIVED_CHAT_MODULE = 'ARCHIVED_CHAT';
export const ANNOUNCEMENT_MODULE = 'ANNOUNCEMENT';
export const CASH_BOOK_MODULE = 'CASH_BOOK';
export const BALANCE_SHEET_MODULE = 'BALANCE_SHEET';
export const ACCOUNTS_RECEIVABLE_REPORT_MODULE = 'ACCOUNTS_RECEIVABLE_REPORT';
export const ADVANCE_PAYMENT_REPORT_MODULE = 'ADVANCE_PAYMENT_REPORT';
export const ACCOUNTING_MODULE = 'ACCOUNTING';
export const CARD_MODULE = 'CARD';
export const CARD_REGISTRATION_FORM_MODULE = 'CARD_REGISTRATION_FORM';
export const TASK_MODULE = 'TASK';
export const ASSET_MODULE = 'ASSET';
export const SUPPLY_MODULE = 'SUPPLY';
export const ASSET_PARTNER_MODULE = 'ASSET_PARTNER';
export const HELPDESK_MODULE = 'HELPDESK';
export const ACCESS_CONTROL_MODULE = 'ACCESS_CONTROL';
export const GOODS_MOVING_MODULE = 'GOODS_MOVING';
export const CONSTRUCTION_REGISTRATION_MODULE = 'CONSTRUCTION';
export const BUSINESS_CONTRACT_MODULE = 'BUSINESS_CONTRACT';
export const COMPANY_MODULE = 'COMPANY';
export const EVENT_MODULE = 'EVENT';
export const NEWSLETTER_MODULE = 'NEWSLETTER';
export const INFORMATION_BOARD_MODULE = 'INFORMATION_BOARD';
export const RANKING_MODULE = 'RANKING';
