import { put, call, all, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { getsAmenitiesOrderService, exportAmenitiesOrderService } from 'services/amenities';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import { AMENITIES_ORDER } from './amenities.types';
import { AMENITIES_ORDER_STATUS_ALL } from './amenities.constants';

function* gets(action) {
  try {
    const { filter, unitGroupId, limit, page } = action.payload;
    const headers = {
      [X_UNIT_GROUP_ID]: unitGroupId,
    };
    const params = {
      from: filter?.orderTime?.length ? moment(filter.orderTime[0]).startOf('day').toISOString() : undefined,
      to: filter?.orderTime?.length ? moment(filter.orderTime[1]).endOf('day').toISOString() : undefined,
      dateUsageFrom: filter?.usageTime?.length ? moment(filter.usageTime[0]).startOf('day').toISOString() : undefined,
      dateUsageTo: filter?.usageTime?.length ? moment(filter.usageTime[1]).endOf('day').toISOString() : undefined,
      status: filter.status !== AMENITIES_ORDER_STATUS_ALL ? filter.status : undefined,
      limit,
      code: filter.code || undefined,
      name: filter.name || undefined,
      groupName: filter.groupName || undefined,
      unitName: filter.unitName || undefined,
      residentName: filter.residentName || undefined,
      page,
    };
    const result = yield call(getsAmenitiesOrderService, { params, headers });
    yield put({
      type: AMENITIES_ORDER.GETS.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: AMENITIES_ORDER.GETS.FAIL,
      payload: error,
    });
  }
}

function* exportData(action) {
  try {
    const result = yield call(exportAmenitiesOrderService, action.payload, AMENITIES_ORDER.EXPORT);
    yield put({
      type: AMENITIES_ORDER.EXPORT.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: AMENITIES_ORDER.EXPORT.FAIL,
      payload: error,
    });
  }
}

export default function* watch() {
  yield all([takeLatest(AMENITIES_ORDER.GETS.REQUEST, gets), takeLatest(AMENITIES_ORDER.EXPORT.REQUEST, exportData)]);
}
