/* eslint-disable import/no-cycle */
import { useContext } from 'react';
import { Context } from 'globalContext';
import { getTotalRequestExpiredService } from 'services/request';
import { countHelpDeskBadgeService } from 'services/helpDesk';
import { countChatBadgesService } from 'services/chat';
import { countBadgesAmenitiesBookingService } from 'services/amenities';
import { countBadgesAccessManagementService } from 'services/accessManagement';
import { countBadgesInventoryTransferService } from 'services/inventoryTransfer';
import { countBadgesNotificationOfBuildingsService, countBadgesNotificationService } from 'services/notification';
import { countBadgesConstructionService } from 'services/construction';
import { countBadgesEventService } from 'services/event';
import { countBadgesInformationBoardService } from 'services/informationBoard';

const useBadge = () => {
  const { badges, setBadges, setBadgeNotificationOfBuilding, badgeNotificationOfBuilding } = useContext(Context);

  const getBadgeNotificationOfBuildings = async (userId, buildings) => {
    if (!buildings.length) {
      return;
    }
    const buildingIds = buildings.map((building) => building.id);
    try {
      const badgeNotificationOfBuildingObj = {};
      const countBadgesNotificationOfBuildingData = await countBadgesNotificationOfBuildingsService(userId, buildingIds);
      countBadgesNotificationOfBuildingData.data.forEach(({ unitGroupId, badge }) => {
        badgeNotificationOfBuildingObj[unitGroupId] = badge;
      });
      setBadgeNotificationOfBuilding(badgeNotificationOfBuildingObj);
    } catch (error) {
      setBadgeNotificationOfBuilding({});
    }
  };

  const getBadges = async (idBuilding) => {
    const [
      requestBadge,
      helpDeskBadge,
      chatBadges,
      amenitiesBadges,
      accessManagementBadges,
      inventoryTransferBadges,
      constructionBadges,
      notificationBadges,
      eventBadges,
      informationBoardBadges,
    ] = await Promise.all([
      getTotalRequestExpiredService(idBuilding),
      countHelpDeskBadgeService(idBuilding),
      countChatBadgesService(idBuilding),
      countBadgesAmenitiesBookingService(idBuilding),
      countBadgesAccessManagementService(idBuilding),
      countBadgesInventoryTransferService(idBuilding),
      countBadgesConstructionService(idBuilding),
      countBadgesNotificationService(idBuilding),
      countBadgesEventService(idBuilding),
      countBadgesInformationBoardService(idBuilding),
    ]);

    const requestBadgeOfBuilding = requestBadge.data.find((badge) => badge.unitGroupId === +idBuilding);

    setBadges({
      request: requestBadgeOfBuilding?.overDue ?? 0,
      helpDesk: helpDeskBadge?.data?.badge ?? 0,
      chat: chatBadges?.data?.badge ?? 0,
      amenities: amenitiesBadges?.data ?? 0,
      accessManagement: accessManagementBadges?.data?.badge ?? 0,
      inventoryTransfer: inventoryTransferBadges?.data?.badge ?? 0,
      construction: constructionBadges?.data?.badge ?? 0,
      notification: notificationBadges?.data?.badge ?? 0,
      event: eventBadges?.data?.badge ?? 0,
      informationBoard: informationBoardBadges?.data?.badge ?? 0,
    });
  };

  const getHelpDeskBadges = async (idBuilding) => {
    if (badges.helpDesk === 0) {
      const result = await countHelpDeskBadgeService(idBuilding);
      setBadges({
        ...badges,
        helpDesk: result?.data?.badge ?? 0,
      });
    }
  };

  const getChatBadges = async (idBuilding) => {
    if (badges.chat === 0) {
      const chatBadges = await countChatBadgesService(idBuilding);
      setBadges({
        ...badges,
        chat: chatBadges?.data?.badge ?? 0,
      });
    }
  };

  const getAccessManagementBadges = async (idBuilding) => {
    if (badges.accessManagement === 0) {
      const accessManagementBadges = await countBadgesAccessManagementService(idBuilding);
      setBadges({
        ...badges,
        accessManagement: accessManagementBadges?.data?.badge ?? 0,
      });
    }
  };

  const getInventoryTransferBadges = async (idBuilding) => {
    if (badges.inventoryTransfer === 0) {
      const inventoryTransferBadges = await countBadgesInventoryTransferService(idBuilding);
      setBadges({
        ...badges,
        inventoryTransfer: inventoryTransferBadges?.data?.badge ?? 0,
      });
    }
  };

  const getConstructionBadges = async (idBuilding) => {
    if (badges.construction === 0) {
      const constructionBadges = await countBadgesConstructionService(idBuilding);
      setBadges({
        ...badges,
        construction: constructionBadges?.data?.badge ?? 0,
      });
    }
  };

  const getNotificationBadges = async (idBuilding) => {
    const notificationBadges = await countBadgesNotificationService(idBuilding);
    setBadges({
      ...badges,
      notification: notificationBadges?.data?.badge ?? 0,
    });
    return notificationBadges?.data?.badge ?? 0;
  };

  const getAmenitiesBookingBadges = async (idBuilding) => {
    const amenitiesBookingBadges = await countBadgesAmenitiesBookingService(idBuilding);
    setBadges({
      ...badges,
      amenities: amenitiesBookingBadges?.data ?? 0,
    });
  };

  const getRequestBadges = async (idBuilding) => {
    const requestBadge = await getTotalRequestExpiredService(idBuilding);
    const requestBadgeOfBuilding = requestBadge.data.find((badge) => badge.unitGroupId === +idBuilding);
    setBadges({
      ...badges,
      request: requestBadgeOfBuilding?.overDue ?? 0,
    });
  };

  const getEventBadges = async (idBuilding) => {
    if (badges.event === 0) {
      const eventBadges = await countBadgesEventService(idBuilding);
      setBadges({
        ...badges,
        event: eventBadges?.data?.badge ?? 0,
      });
    }
  };

  const getInformationBoardBadges = async (idBuilding) => {
    if (badges.informationBoard === 0) {
      const informationBoardBadges = await countBadgesInformationBoardService(idBuilding);
      setBadges({
        ...badges,
        informationBoard: informationBoardBadges?.data?.badge ?? 0,
      });
    }
  };

  return {
    badges,
    setBadges,
    getBadges,
    getHelpDeskBadges,
    getChatBadges,
    getAccessManagementBadges,
    getInventoryTransferBadges,
    getConstructionBadges,
    getNotificationBadges,
    getAmenitiesBookingBadges,
    getRequestBadges,
    getEventBadges,
    getInformationBoardBadges,
    getBadgeNotificationOfBuildings,
    badgeNotificationOfBuilding,
  };
};

export default useBadge;
