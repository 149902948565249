import { API_TYPE_CALL_API, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const EVENT_GETS = `${SERVER}/event`;
const EVENT_SEND = `${SERVER}/event`;
const EVENT_GET_DETAIL = (id) => `${SERVER}/event/${id}`;
const EVENT_CANCEL = (id) => `${SERVER}/event/${id}/cancel`;
const EVENT_UPDATE = (id) => `${SERVER}/event/${id}`;
const EVENT_UNIT_SEEN_GETS = (id) => `${SERVER}/event/${id}/units`;
const EVENT_COUNT_BADGES = `${SERVER}/event/count-badges`;
const EVENT_STATISTIC = (id) => `${SERVER}/event/${id}/attendee`;
const EVENT_CHECK_IN = (id) => `${SERVER}/event/${id}/check-in`;

export async function getEventService({ idBuilding, filter, page, limit }) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const params = {
    ...filter,
    page,
    limit,
  };
  const response = api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: EVENT_GETS,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function sendEventService(idBuilding, event) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const body = {
    ...event,
  };
  const response = api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: EVENT_SEND,
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function updateEventService(idEvent, event, idBuilding) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const body = {
    ...event,
  };
  const response = api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: EVENT_UPDATE(idEvent),
      method: METHOD.PATCH,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function cancelEventService(idBuilding, idEvent, reason) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const body = {
    reason,
  };
  const response = api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: EVENT_CANCEL(idEvent),
      method: METHOD.PATCH,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function getUnitSeenService({ idBuilding, idEvent, filter, page, limit }) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: EVENT_UNIT_SEEN_GETS(idEvent),
      method: METHOD.GET,
      options: {
        headers,
        params: {
          ...filter,
          page,
          limit,
        },
      },
    },
  });

  return response;
}

export const countBadgesEventService = async (idBuilding) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: EVENT_COUNT_BADGES,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export async function getEventDetailService({ idBuilding, idEvent }) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: EVENT_GET_DETAIL(idEvent),
      method: METHOD.GET,
      options: {
        headers,
      },
    },
  });

  return response;
}

export async function statisticEventService({ idBuilding, idEvent, filter, page, limit }) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const response = api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: EVENT_STATISTIC(idEvent),
      method: METHOD.GET,
      options: {
        headers,
        params: {
          ...filter,
          page,
          limit,
        },
      },
    },
  });
  return response;
}

export async function checkInEventService(idBuilding, residentId, eventId) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const body = {
    residentId,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: EVENT_CHECK_IN(eventId),
      method: METHOD.PATCH,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}
